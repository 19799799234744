.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.styled-link {
  text-decoration: none;
}

.styled-link:hover {
  text-decoration: underline;
}

.outlined {
  filter: drop-shadow(white 0 0 0.5px) drop-shadow(white 0 0 0.5px) drop-shadow(white 0 0 0.5px) drop-shadow(white 0 0 0.5px) drop-shadow(white 0 0 0.5px);
  -webkit-filter: drop-shadow(white 0 0 0.5px) drop-shadow(white 0 0 0.5px) drop-shadow(white 0 0 0.5px) drop-shadow(white 0 0 0.5px) drop-shadow(white 0 0 0.5px);
}

.player-highlighted {
  box-shadow: 0px 0px 2px yellow, 0px 0px 2px yellow, 0px 0px 2px yellow, 0px 0px 2px yellow, 0px 0px 2px yellow;
}

/* spin animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(3600deg);
  }
}