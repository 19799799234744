body {
  margin: 0;
  font-family: "Renogare", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (min-width: 960px) {
  html {
    /* margin-left: calc(100vw - 100%);
    margin-right: 0; */
    margin-right: calc(-100vw + 100%);
    /* width: 100vw; */
    overflow-x: hidden;
  }
}

@font-face {
  font-family: "Renogare";
  src: url(fonts/Renogare-Regular.otf) format("opentype");
}